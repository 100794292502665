
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import AtomCheckbox from '@/components/atoms/AtomCheckbox.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomActionText from '@/components/atoms/AtomActionText.vue';
import AtomDropdownAddressSearch, { OptionCategory } from '@/components/atoms/AtomDropdownAddressSearch.vue';
import MoleculeHereMap from '@/components/molecules/maps/MoleculeHereMap.vue';

import BaseMarkerModel from '@twogo/here-maps/src/lib/models/BaseMarkerModel';
import RouteReqModel from '@twogo/geolocation/src/lib/models/routing/requests/RouteReqModel';
import RouteModel from '@twogo/geolocation/src/lib/models/routing/RouteModel';
import CoordinatesModel from '@twogo/geolocation/src/lib/models/CoordinatesModel';
import SwitchEnum from '@/enums/switch/SwitchEnum';
import TransportModeEnum from '@/enums/rides/TransportModeEnum';
import RoutingModeEnum from '@/enums/rides/RoutingModeEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import FeaturesEnum from '@/enums/settings/FeatureEnum';

import getImageURL from '@/services/assets/AssetsService';
import {
  browserGeolocation,
  route,
  revGeoCode,
  addToSearchHistory,
} from '@/services/geolocation/geolocationService';
import getGenericAddresses from '@/services/geolocation/genericAddressBuilder';

import {
  getDynamicSvg,
  createMarkerBase,
  resizeMap,
  createMarker,
} from '@twogo/here-maps/src/lib/HereMapsService';

import locationPin from '@/assets/images/icons/icon-pin-start.svg';
import locationFlag from '@/assets/images/icons/icon-pin-flag.svg';

import GenericAddressModel from '@/models/geolocation/GenericAddressModel';
import SourceEnum from '@/enums/geolocation/SourceEnum';
import { quicksearch } from '@/api/geolocation/quicksearch/quicksearchApi';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';

import { hasFeature } from '@/services/feature/FeatureService';

export default Vue.extend({
  name: 'OrganismReturnTrip',
  data: () => ({
    routes: [] as RouteModel[],
    geoSuggestions: [] as GenericAddressModel[],
    RolesEnum,
    SwitchEnum,
    FeaturesEnum,
    hereMap: undefined as any,
    hereMapGroup: undefined as any,
    locationHintMarker: undefined as any,
    geoSearchString: '',
  }),
  components: {
    AtomCheckbox,
    AtomText,
    AtomActionText,
    AtomDropdownAddressSearch,
    MoleculeHereMap,
    // eslint-disable-next-line vue/no-unused-components
    AtomSvgIcon,
  },
  computed: {
    ...mapGetters('rideIntent', [
      'getSelectedRole',
      'getReturnTripStatus',
      'getReturnStartCoordinates',
      'getReturnEndCoordinates',
      'getReturnStartPoint',
      'getReturnEndPoint',
      'getReturnWaypoints',
      'maxNrOfReturnWaypointsReached',
      'getReturnMeetingPoint',
      'isPoolCarRequest',
      'isEditMode',
    ]),
    ...mapGetters('user', ['getCidaasUserData', 'b2cFree', 'features']),
    ...mapGetters('poi', ['getPoi', 'getHomePoi']),
    categories(): OptionCategory[] {
      return [
        { value: SourceEnum.ALL, title: 'dictionary.all', selected: true, isVisible: true },
        { value: SourceEnum.FAVORITES, title: 'dictionary.favorites', selected: false, isVisible: true },
        { value: SourceEnum.HISTORY, title: 'dictionary.history', selected: false, isVisible: true },
        { value: SourceEnum.SITE, title: 'dictionary.places', selected: false, isVisible: !this.b2cFree },
      ];
    },
    getMarkerLocations(): BaseMarkerModel[] {
      const markers = [] as BaseMarkerModel[];

      if (this.getReturnStartPoint.location !== null) {
        markers.push(
          createMarkerBase(
            locationPin,
            this.getReturnStartPoint.location.position,
            this.getReturnStartPoint.id,
          ),
        );
      }
      this.getReturnWaypoints.forEach((stopOver, index) => {
        if (stopOver.location !== null) {
          markers.push(
            createMarkerBase(getDynamicSvg(index), stopOver.location.position, stopOver.id),
          );
        }
      });
      if (this.getReturnEndPoint.location !== null) {
        markers.push(
          createMarkerBase(
            locationFlag,
            this.getReturnEndPoint.location.position,
            this.getReturnEndPoint.id,
          ),
        );
      }
      return markers;
    },
    getGeolocation(): GenericAddressModel[] {
      const { getPoi, geoSuggestions, geoSearchString } = this;
      return [
        ...(geoSearchString || geoSuggestions.length ? [] : getGenericAddresses(getPoi)),
        ...this.geoSuggestions,
      ];
    },
  },
  methods: {
    ...mapActions('rideIntent', [
      'toggleOutwardTripSteps',
      'addReturnStartPointLocation',
      'addReturnEndPointLocation',
      'addReturnWaypoint',
      'updateReturnWaypoint',
      'removeReturnWaypoint',
      'validateReturnTripRoute',
      'autoCompleteReturnTripDetails',
      'switchReturnOriginAndArrival',
      'setReturnMeetingPoint',
    ]),
    hasFeature,
    locationHover(coordinates) {
      if (this.hereMap) {
        if (!this.locationHintMarker) {
          this.locationHintMarker = createMarker({ coordinates, icon: locationPin as any });
          this.hereMap.addObject(this.locationHintMarker);
        }
        this.locationHintMarker.setGeometry(coordinates);
        this.hereMap.setCenter(coordinates);
        this.hereMap.setZoom(14);
      }
    },
    locationHoverEnd() {
      if (this.locationHintMarker) {
        this.hereMap.removeObject(this.locationHintMarker);

        this.locationHintMarker = undefined as any;
        resizeMap(this.hereMap, this.hereMapGroup);
      }
    },
    async localizeMe(id: string) {
      const { success, coordinates, message = '' } = await browserGeolocation();
      if (success && coordinates) {
        const { data } = await revGeoCode({ at: coordinates });
        this.applyAddressChangeOnMarkerDrag(getGenericAddresses(data), id);
      } else {
        toast(this.$bvToast, message, ToastVariants.DANGER);
      }
    },
    getImagePath(icon: string): string {
      return getImageURL(icon);
    },
    addStopover() {
      if (!this.maxNrOfReturnWaypointsReached) {
        this.addReturnWaypoint().then(() => {
          this.validateReturnTripRoute();
          this.setRouteSections();
        });
      }
    },
    removeStopover(index: number) {
      this.removeReturnWaypoint(index).then(() => {
        this.validateReturnTripRoute();
        this.setRouteSections();
      });
    },
    updateStopover({ index, location }) {
      this.updateReturnWaypoint({ location, index }).then(() => {
        this.validateReturnTripRoute();
        this.setRouteSections();
        this.geoSuggestions = [];
        this.geoSearchString = '';
      });
      if (location) addToSearchHistory(this.getCidaasUserData.sub, [location]);
    },
    async calculateRoute(query: RouteReqModel) {
      const { data } = await route(query);
      return data;
    },
    async getGeoSuggestions(location: string, loading) {
      if (location.length >= 3) {
        loading(true);
        const { data } = await quicksearch(location);
        this.geoSuggestions = data;
        this.geoSearchString = location;
        loading(false);
      } else {
        this.geoSuggestions = [];
        this.geoSearchString = '';
        loading(false);
      }
    },
    async searchAddressByCoordinates(coordinates: CoordinatesModel, id: string) {
      const { data } = await revGeoCode({ at: coordinates });
      this.applyAddressChangeOnMarkerDrag(getGenericAddresses(data), id);
    },
    applyAddressChangeOnMarkerDrag(results: GenericAddressModel[], id: string) {
      if (!results.length) return;
      const location = results[0];
      const {
        position: { lng, lat },
      } = location;

      if (
        this.getReturnStartPoint.id === id &&
        (!this.getReturnStartPoint.location ||
          (!lng !== this.getReturnStartPoint.location.position.lng &&
            lat !== this.getReturnStartPoint.location.position.lat))
      ) {
        this.addStartPoint(results[0]);
      }
      if (
        this.getReturnEndPoint.id === id &&
        (!this.getReturnEndPoint.location ||
          (lng !== this.getReturnEndPoint.location.position.lng &&
            lat !== this.getReturnEndPoint.location.position.lat))
      ) {
        this.addEndPoint(results[0]);
      }
      let indexOfWaypoint = 0;
      if (
        this.getReturnWaypoints.some((waypoint, index) => {
          if (
            waypoint.id === id &&
            (!waypoint.location ||
              (lng !== waypoint.location.position.lng && lat !== waypoint.location.position.lat))
          ) {
            indexOfWaypoint = index;
            return true;
          }
          return false;
        })
      ) {
        this.updateStopover({ index: indexOfWaypoint, location: results[0] });
      }
    },
    addStartPoint(location: GenericAddressModel) {
      this.addReturnStartPointLocation(location).then(() => {
        this.validateReturnTripRoute();
        this.setRouteSections();
        this.geoSuggestions = [];
        this.geoSearchString = '';
      });
      // if (location) addToSearchHistory(this.getCidaasUserData.sub, [location]);
    },
    addEndPoint(location: GenericAddressModel) {
      this.addReturnEndPointLocation(location).then(() => {
        this.validateReturnTripRoute();
        this.setRouteSections();
        this.geoSuggestions = [];
        this.geoSearchString = '';
      });
      // if (location) addToSearchHistory(this.getCidaasUserData.sub, [location]);
    },
    setRouteSections() {
      const markerList = this.getMarkerLocations;
      const nrOfMarkers = markerList.length;
      if (nrOfMarkers > 1) {
        const stopOverCoordinates: CoordinatesModel[] = [];
        markerList.forEach(({ coordinates }: BaseMarkerModel, index: number) => {
          if (index !== 0 && index !== nrOfMarkers - 1) {
            stopOverCoordinates.push(coordinates);
          }
        });

        const queryModel = {
          transportMode: TransportModeEnum.CAR,
          origin: markerList[0].coordinates,
          destination: markerList[nrOfMarkers - 1].coordinates,
          routingMode: RoutingModeEnum.FAST,
          via: stopOverCoordinates,
        } as RouteReqModel;

        this.calculateRoute(queryModel).then((routes) => {
          if (routes) {
            this.routes = routes;
          }
        });
      }
    },
    switchDirection() {
      this.switchReturnOriginAndArrival().then(() => {
        this.validateReturnTripRoute();
        this.setRouteSections();
      });
    },
  },
  mounted() {
    if (
      (!this.getReturnStartCoordinates && !this.getReturnEndCoordinates) ||
      this.isPoolCarRequest
    ) {
      this.autoCompleteReturnTripDetails().then(() => {
        this.validateReturnTripRoute();
      });
    }
    this.setRouteSections();
  },
});
